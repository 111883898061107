const { mapState } = require("vuex");
module.exports = {
    computed: mapState({
        count: (state) => state.count,
        countAlias: "count",
        countPlusLocalState(state) {
            return state.count + this.localCount;
        },
    }),
    baseUrl: "http://localhost:8084/",
    serverBaseUrl: "https://acc.vestatest.ir",
};