<template>
  <notifications />
  <router-view class="h-full"></router-view>
</template>

<script>
  // Prime Vue ************************
  //theme
  import "primevue/resources/themes/lara-light-indigo/theme.css";

  //core
  import "primevue/resources/primevue.min.css";
  //************************

  export default {
    name: "App",
  };
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
    /* margin-top: 60px; */
  }
</style>
