import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import state from "./store/state";
import Notifications from "@kyvg/vue3-notification";
import PrimeVue from "primevue/config";
import "./style.css";
import "./assets/sass/index.sass";
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";

import axios from "axios";
axios.defaults.baseURL = state.serverBaseUrl + "/api";
axios.defaults.headers.common["Content-Type"] = "Application/json";
axios.defaults.headers.common.Accept = "Application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

const app = createApp(App);

app.mixin({
  methods: {
    $scrollPage() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
});

app.use(router);
app.use(state);
app.use(Notifications);
app.mount("#app");
app.use(PrimeVue);
app.component("DatePicker", Vue3PersianDatetimePicker);
