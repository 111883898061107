import { createRouter, createWebHashHistory } from "vue-router";

const isAuthenticated = localStorage.getItem("token");

const routes = [
  {
    path: "/",
    name: "login_page",
    beforeEnter: (to, from, next) => {
      if (isAuthenticated) {
        next("/dashboard");
      } else {
        localStorage.clear();
        next();
      }
    },
    component: () => import("@/views/login-items/login-page.vue"),
  },
  {
    name: "dashboard-page",
    path: "/dashboard",
    meta: { requiresAuth: true },
    component: () => import("@/layouts/Dashboard/dashboard-page.vue"),
    children: [
      {
        name: "base-information",
        path: "/dashboard/base-information",
        meta: { requiresAuth: true },
        children: [
          {
            name: "goods-and-services",
            path: "/dashboard/base-information/goods-and-services",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/goods-and-services/goods-and-services.vue"),
          },
          {
            name: "product-categorization",
            path: "/dashboard/base-information/product-categorization",
            meta: { requiresAuth: true },
            component: () =>
              import(
                "@/views/product-categorization/product-categorization.vue"
              ),
          },
          {
            name: "account-party",
            path: "/dashboard/base-information/account-party",
            meta: { requiresAuth: true },
            component: () => import("@/views/account-party/account-party.vue"),
          },
          {
            name: "account-party-group",
            path: "/dashboard/base-information/account-party-group",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/account-party-group/account-party-group.vue"),
          },
        ],
      },

      {
        name: "bank-pages",
        path: "/dashboard/bank-pages",
        meta: { requiresAuth: true },
        children: [
          {
            name: "bank-account",
            path: "/dashboard/bank-pages/bank-account",
            meta: { requiresAuth: true },
            component: () => import("@/views/Banks/bank-account.vue"),
          },
        ],
      },
      {
        name: "sales-pages",
        path: "/dashboard/sales-pages",
        meta: { requiresAuth: true },
        children: [
          {
            name: "sales-invoices",
            path: "/dashboard/sales-pages/sales-invoices",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/sales-invoices/sales-invoices.vue"),
          },
          {
            name: "return-invoices",
            path: "/dashboard/sales-pages/return-invoices",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/sales-invoices/return-invoices.vue"),
          },
          {
            name: "purchase-order",
            path: "/dashboard/sales-pages/purchase-order",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/sales-invoices/purchase-order.vue"),
          },
        ],
      },
      {
        name: "purchase-pages",
        path: "/dashboard/purchase-pages",
        meta: { requiresAuth: true },
        children: [
          {
            name: "accounting-purchase",
            path: "/dashboard/purchase-pages/accounting-purchase",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/purchase-pages/accounting-purchase.vue"),
          },
          {
            name: "all-return-purchase-invoices",
            path: "/dashboard/purchase-pages/all-return-purchase-invoices",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/purchase-pages/all-return-purchase-invoices.vue"),
          },
        ],
      },
      {
        name: "storage-pages",
        path: "/dashboard/storage-pages",
        meta: { requiresAuth: true },
        children: [
          {
            name: "storage-count",
            path: "/dashboard/storage-pages/storage-count",
            meta: { requiresAuth: true },
            component: () => import("@/views/storage/storage-count.vue"),
          },
        ],
      },
      {
        name: "chest-page",
        path: "/dashboard/chest-page",
        meta: { requiresAuth: true },
        component: () => import("@/views/chest-page/chest-page.vue"),
      },
      {
        name: "measurement-unit",
        path: "/dashboard/measurement-unit",
        meta: { requiresAuth: true },
        children: [
          {
            name: "product-unit",
            path: "/dashboard/measurement-unit/product-unit",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/measurement-unit/product-unit.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound-page",
    component: () => import("@/layouts/full-page/PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next("/");
    }
  }

  next();
});

export default router;
